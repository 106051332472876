import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* eslint-disable */
const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 60 },
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    // Redirect for downloading the app
    {
      path: "/download",
      beforeEnter(to, from, next) {
        window.location.replace(
          "https://apps.apple.com/us/app/kioskbuddy/id1452458580?ls=1"
        );
      },
    },

    // Redirect for booking calls
    {
      path: "/book",
      beforeEnter(to, from, next) {
        window.location.replace("https://calendly.com/kioskbuddy/chat");
      },
    },

    // Redirect for signing up
    {
      path: "/signup",
      beforeEnter(to, from, next) {
        window.location.replace("https://dashboard.kioskbuddy.app/signup");
      },
    },

    // Redirect for demo video
    {
      path: "/demo",
      beforeEnter(to, from, next) {
        window.location.replace("https://www.kioskbuddy.app/videos/demo");
      },
    },

    // General routes
    {
      path: "/",
      name: "landing",
      component: () => import("./views/Landing"),
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: () => import("./views/Calendar.vue"),
    },
    {
      path: "/videos",
      name: "Videos",
      component: () => import("./videos/Videos.vue"),
    },
    {
      path: "/support",
      name: "Support",
      component: () => import("./views/Support.vue"),
    },
    {
      path: "/confirmation",
      name: "Confirmation",
      component: () => import("./views/Confirmation.vue"),
    },
    {
      path: "/confirmation-support",
      name: "ConfirmationSupport",
      component: () => import("./views/ConfirmationSupport.vue"),
    },
    {
      path: "/square",
      name: "Square",
      component: () => import("./views/Square.vue"),
    },
    {
      path: "/about",
      name: "About",
      component: () => import("./views/About.vue"),
    },
    {
      path: "/testimonials",
      name: "Testimonials",
      component: () => import("./views/Testimonials.vue"),
    },
    {
      path: "/thanks",
      name: "Thanks",
      component: () => import("./views/Thanks.vue"),
    },
    {
      path: "/sales",
      name: "Sales",
      component: () => import("./views/Sales.vue"),
    },
    {
      path: "/savings",
      name: "Savings",
      component: () => import("./views/Savings.vue"),
    },
    {
      path: "/privacy",
      name: "PrivacyPolicy",
      component: () => import("./views/Privacy.vue"),
    },
    {
      path: "/terms",
      name: "TermsOfService",
      component: () => import("./views/Terms.vue"),
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: () => import("./views/Pricing.vue"),
    },
    {
      path: "/jobs",
      name: "Jobs",
      component: () => import("./views/Jobs.vue"),
    },
    {
      path: "/receipts/:id",
      component: () => import("./views/Receipt.vue"),
    },

    // Blog posts
    {
      path: "/blog",
      name: "Blog",
      component: () => import("./blog/Blog.vue"),
    },
    {
      path: "/blog/:slug",
      name: "Blog Post",
      component: () => import("./blog/BlogPost.vue"),
    },
    {
      path: "/blog-preview/:slug",
      name: "Blog Post Preview",
      component: () => import("./blog/BlogPostPreview.vue"),
    },

    // Customer stories
    {
      path: "/customer-stories",
      name: "CustomerStories",
      component: () => import("./customer-stories/CustomerStories.vue"),
    },
    {
      path: "/customer-stories/:slug",
      name: "Customer Story",
      component: () => import("./customer-stories/CustomerStoryContent.vue"),
    },
    {
      path: "/customer-story-preview/:slug",
      name: "Customer Story Content Preview",
      component: () => import("./customer-stories/CustomerStoryContentPreview.vue"),
    },

    // Help
    {
      path: "/help/",
      name: "Help Home",
      component: () => import("./help/HelpCenter.vue"),
    },
    {
      path: "/help/:slug",
      name: "Knowledge Base Content",
      component: () => import("./help/KnowledgeBaseContent.vue"),
    },
    {
      path: "/help-preview/:slug",
      name: "Knowledge Base Content Preview",
      component: () => import("./help/KnowledgeBaseContentPreview.vue"),
    },

    // Videos
    {
      path: "/videos",
      name: "Videos",
      component: () => import("./videos/Videos.vue"),
    },
    {
      path: "/videos/:slug",
      name: "Video",
      component: () => import("./videos/Video.vue"),
    },
    // Catch all Uh Oh page
    {
      path: "*",
      name: "uhoh",
      component: () => import("./views/UhOh.vue"),
    },
  ],
});
/* eslint-enable */

export default router;
